import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import {
  ICanonicalTravelDestination,
  TravelDestinationLabelCaption,
  TravelDestinationSuggestion,
} from '@hotelplan/components.common.travel-destination';

const HotelOnlyLabel = styled.div(({ theme: { fontSizes, colors } }) => ({
  display: 'flex',
  fontSize: fontSizes[2],
  color: colors.darkGray,
}));

const HotelOnlyLabelText = styled.span({
  marginLeft: '38px',
});

interface ITravelDestinationHotelOnlySuggestionProps {
  item: ICanonicalTravelDestination;
  query: string;
}

const TravelDestinationHotelOnlySuggestion: React.FC<ITravelDestinationHotelOnlySuggestionProps> = ({
  item,
  query,
}) => {
  const [t] = useTranslation('common');

  return (
    <>
      <TravelDestinationSuggestion
        item={{ ...item, countState: { count: 0, loading: false } }}
        query={query}
      />
      <HotelOnlyLabel>
        <HotelOnlyLabelText>{t('onlyHotelOffersAvailable')}</HotelOnlyLabelText>
        <TravelDestinationLabelCaption item={item} />
      </HotelOnlyLabel>
    </>
  );
};

export default TravelDestinationHotelOnlySuggestion;
