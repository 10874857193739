import React from 'react';
import styled from 'styled-components';
import { AutocompleteCountProvider } from '@hotelplan/components.common.autocomplete';
import { useFormContext } from '@hotelplan/components.common.forms';
import { ICanonicalTravelDestination } from '@hotelplan/components.common.travel-destination';
import { DeviceType, PageLanguage } from '@hotelplan/graphql.types';
import { useRequestContext } from '@hotelplan/libs.context.req-ctx';
import { TravelType } from '@hotelplan/platform-graphql-api';
import { mapSearchControlToCriteria } from 'components/domain/searchControl/SearchControl.mappers';
import { ISearchControlFormState } from 'components/domain/searchControl/SearchControl.types';
import {
  GetAutocompleteDestinationCountDocument,
  GetAutocompleteDestinationCountQuery,
  GetAutocompleteDestinationCountQueryVariables,
} from 'graphql/autocomplete/GetAutocompleteDestinationCount.generated';
import { useTravelDestinationAutocompleteCountFilters } from './useTravelDestinationAutocompleteCountFilters';
import { useTravelDestinationAutocompleteHotelOnlyDestinations } from './useTravelDestinationAutocompleteHotelOnlyDestinations';

const TravelDestinationFieldContent = styled.span(({ theme: { colors } }) => ({
  '.trigger-input': {
    input: {
      border: 'none',
      marginTop: '-1px',
      marginBottom: '-1px',
    },
    '.base-input-wrapper': {
      display: 'flex',
      alignItems: 'center',
      border: '1px solid',
      borderColor: colors.borderColor,
      overflow: 'hidden',
    },
  },
}));

const TravelDestinationFieldWithCount: React.FC<{
  children?: React.ReactNode;
  searchControlState?: ISearchControlFormState;
}> = ({ children, searchControlState }) => {
  const reqContext = useRequestContext<DeviceType, PageLanguage>();
  const { values: formValues } = useFormContext<ISearchControlFormState>();
  const values =
    searchControlState &&
    formValues?.travelDestination?.destinations.length === 1
      ? searchControlState
      : formValues;
  const filters = useTravelDestinationAutocompleteCountFilters(
    values.travelType
  );
  const hotelOnlyDestinations = useTravelDestinationAutocompleteHotelOnlyDestinations();

  return (
    <AutocompleteCountProvider<
      ICanonicalTravelDestination,
      GetAutocompleteDestinationCountQuery,
      GetAutocompleteDestinationCountQueryVariables
    >
      queryDocument={GetAutocompleteDestinationCountDocument}
      dataMapper={data => {
        if (data?.srl?.search?.single?.__typename == 'SrlSingleResult') {
          return data.srl.search.single.productsTotal;
        }

        return 0;
      }}
      variablesMapper={items => {
        const searchControl = mapSearchControlToCriteria<ISearchControlFormState>(
          values
        );

        searchControl.travelDestinations = items.map(item => ({
          id: item.id,
          type: item.type,
          name: item.name,
          mythosCode: item.mythosCode,
        }));

        if (
          hotelOnlyDestinations.length &&
          items.filter(item => hotelOnlyDestinations.includes(item.id))
            .length === items.length
        ) {
          searchControl.travelType = TravelType.Hotel;
        }

        return {
          searchControl,
          ...(filters && { filters }),
          context: reqContext,
        };
      }}
    >
      <TravelDestinationFieldContent>{children}</TravelDestinationFieldContent>
    </AutocompleteCountProvider>
  );
};

export default TravelDestinationFieldWithCount;
