import { ISuggestion } from '@hotelplan/libs.tracking';
import { track } from '@hotelplan/libs.tracking-events';

const TOP_DESTINATION_ADD_EVENT_TYPE = 'search.topdestination.add';

export const trackTopDestination = (suggestion: ISuggestion, text: string) => {
  track({
    event: TOP_DESTINATION_ADD_EVENT_TYPE,
    eventMetadata: {
      suggestions: [suggestion],
      text,
      position: suggestion.label.toLowerCase().indexOf(text.toLowerCase()),
      auto: false,
    },
    _clear: true,
  });
};
