import _ from 'lodash';
import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  AutocompleteOptionLoader,
  useAutocompleteContext,
  useAutocompleteCountContext,
} from '@hotelplan/components.common.autocomplete';
import { useFormContext } from '@hotelplan/components.common.forms';
import { ICanonicalTravelDestination } from '@hotelplan/components.common.travel-destination';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { ISearchControlFormState } from 'components/domain/searchControl/SearchControl.types';
import { allowedTravelDestinationItemTypes } from 'components/domain/travel-destination/travelDestination.constants';
import { TTravelDestination } from './TravelDestination.types';

const TriggerInputCaptionContent = styled.span(
  ({ theme: { space, FONT_SIZE, colors } }) =>
    sx2CssThemeFn({
      display: 'flex',
      alignItems: 'center',
      paddingRight: space[3],
      ...FONT_SIZE.LARGE,
      color: colors.secondary,
    })
);

const TravelDestinationTriggerInputCaption = (): ReactElement => {
  const autocompleteContext = useAutocompleteContext<TTravelDestination>();
  const autocompleteCountContext = useAutocompleteCountContext<ICanonicalTravelDestination>();
  const [count, setCount] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { values } = useFormContext<ISearchControlFormState>();

  useEffect(() => {
    const countSummaryItem = autocompleteCountContext.items.find(item => {
      return (
        item.items.length === autocompleteContext.selectedItems.length &&
        _.intersection(
          autocompleteContext?.selectedItems.map(i => i.id),
          item.items.map(i => i.id)
        ).length === item.items.length
      );
    });

    if (countSummaryItem?.loading) {
      setLoading(true);
    } else {
      setLoading(false);

      if (
        countSummaryItem?.count >= 0 &&
        autocompleteContext.selectedItems?.length > 0
      ) {
        setCount(countSummaryItem.count);
      } else {
        setCount(null);
      }
    }
  }, [autocompleteContext.selectedItems, autocompleteCountContext.items]);

  useEffect(() => {
    autocompleteCountContext.clear();

    const selectedItems = autocompleteContext.selectedItems.filter(item => {
      return allowedTravelDestinationItemTypes.includes(item.type) && item.id;
    });

    selectedItems.forEach(item => {
      autocompleteCountContext.putItem({
        id: item.id,
        items: [item as ICanonicalTravelDestination],
      });
    });

    if (
      selectedItems.length > 1 &&
      selectedItems.length === autocompleteContext.selectedItems.length
    ) {
      autocompleteCountContext.putItem({
        id: selectedItems.map(i => i.id).join(';'),
        items: selectedItems as ICanonicalTravelDestination[],
      });
    }
  }, [values.travelType, values.travelRooms, values.travelDates]);

  return (
    <TriggerInputCaptionContent>
      {loading && <AutocompleteOptionLoader />}
      {!loading && count !== null && count}
    </TriggerInputCaptionContent>
  );
};

export default TravelDestinationTriggerInputCaption;
