import { TravelDestinationType } from '@hotelplan/platform-graphql-api';

export const allowedTravelDestinationItemTypes = [
  TravelDestinationType.Continent,
  TravelDestinationType.CountryGroup,
  TravelDestinationType.Country,
  TravelDestinationType.Region,
  TravelDestinationType.Destination,
  TravelDestinationType.Resort,
  TravelDestinationType.Airport,
  TravelDestinationType.AirportGroup,
  TravelDestinationType.Hotel,
];
