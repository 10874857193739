import { useState } from 'react';
import { useTrackFiltersExceptions } from '@hotelplan/libs.tracking';

type TState = {
  count?: number;
  loading?: boolean;
  error?: boolean;
};

type TReturn = {
  setStatus(arg: { count: number; loading: boolean }): void;
  setError(arg: boolean): void;
};

export const useTrackFiltering = (): TReturn => {
  const [state, setFiltersState] = useState<TState>({});

  useTrackFiltersExceptions(state.count, state.loading, state.error);

  const setStatus = status => setFiltersState(status);
  const setError = (error: boolean) => setFiltersState({ error });

  return { setStatus, setError };
};
