import React, { FC } from 'react';
import { AutocompleteCountSource } from '@hotelplan/components.common.autocomplete';
import { ICanonicalTravelDestination } from '@hotelplan/components.common.travel-destination';
import { ISearchControlFormState } from 'components/domain/searchControl/SearchControl.types';
import { allowedTravelDestinationItemTypes } from './travelDestination.constants';
import TravelDestinationTriggerInputCaption from './TravelDestinationTriggerInputCaption';

const TravelDestinationFieldCaption: FC<{
  searchControlState?: ISearchControlFormState;
  objectId?: string;
}> = ({ searchControlState, objectId }) => {
  return (
    <>
      <AutocompleteCountSource<ICanonicalTravelDestination>
        autocompleteItemFilter={(items: ICanonicalTravelDestination[]) => {
          return items.filter(
            item =>
              allowedTravelDestinationItemTypes.includes(item.type) && item.id
          );
        }}
        travelDestinations={searchControlState?.travelDestination?.destinations}
        objectId={objectId}
      />
      <TravelDestinationTriggerInputCaption />
    </>
  );
};

export default TravelDestinationFieldCaption;
