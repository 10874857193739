import { SrlFilterCriteriaInput } from '@hotelplan/graphql.types';
import { useSearchState } from '@hotelplan/libs.search-state';
import { TravelType } from '@hotelplan/platform-graphql-api';
import { mapSrlFilterValuesToSrlFilterCriteriaInput } from 'components/domain/srl/SRL.mappers';
import { ISRLState } from 'components/domain/srl/SRL.types';
import { useGetSrlDefaultFilterValuesQuery } from 'graphql/srl/GetSRLDefaultFilterValues.generated';

export function useTravelDestinationAutocompleteCountFilters(
  travelType: TravelType
): SrlFilterCriteriaInput | undefined {
  const { state: searchState } = useSearchState<ISRLState>();
  const {
    data: srlDefaultFilterValuesData,
  } = useGetSrlDefaultFilterValuesQuery({
    ssr: false,
  });

  if (searchState?.filters) {
    return mapSrlFilterValuesToSrlFilterCriteriaInput(searchState.filters);
  }

  if (
    !searchState &&
    travelType === TravelType.Package &&
    srlDefaultFilterValuesData?.srl?.packageControls?.mainFilter
      ?.departureAirports?.selected?.length
  ) {
    return {
      departureAirports:
        srlDefaultFilterValuesData.srl.packageControls.mainFilter
          .departureAirports.selected,
    };
  }
}
