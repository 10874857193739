import { DocumentNode } from 'graphql';
import React, { useCallback, useState } from 'react';
import { useField, useFormContext } from '@hotelplan/components.common.forms';
import {
  GenericTravelDestinationFieldWithCount,
  ICanonicalTravelDestination,
  IDestinationAutocompleteVariables,
  IDestinationData,
  ITravelDestinationDisableableProps,
  mapCanonicalDestinationToTrackableDestination,
  TravelDestinationSuggestion,
} from '@hotelplan/components.common.travel-destination';
import { TravelType } from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { trackSelectSuggestion } from '@hotelplan/libs.tracking';
import { track } from '@hotelplan/libs.tracking-events';
import SearchHistoryTopDestinationComboSuggestions from 'components/domain/history/SearchHistoryTopDestinationComboSuggestions';
import {
  ISearchControlFormState,
  ISearchControlState,
} from 'components/domain/searchControl/SearchControl.types';
import TravelDestinationFieldCaption from 'components/domain/travel-destination/TravelDestinationFieldCaption';
import TravelDestinationHotelOnlySuggestion from 'components/domain/travel-destination/TravelDestinationHotelOnlySuggestion';
import { useTravelDestinationAutocompleteHotelOnlyDestinations } from 'components/domain/travel-destination/useTravelDestinationAutocompleteHotelOnlyDestinations';
import { trackTopDestination } from './TopDestination.tracking';
import useTopDestinationAutocompleteIds from './useTopDestinationAutocompleteIds';

const trackHotelOnlyDestinationClick = () => {
  track({
    event: `🚀 click.switch`,
    eventMetadata: {
      target: 'hotels-only',
      component: 'autosuggest',
    },
  });
};

interface ITravelDestinationFieldWithTopDestinationsProps<TQuery, TVariables>
  extends ITravelDestinationDisableableProps {
  document: DocumentNode;
  customLabel: React.ReactNode;
  dataMapper: (data: TQuery | undefined) => IDestinationData | undefined;
  variablesMapper: (
    commonVars: IDestinationAutocompleteVariables
  ) => TVariables;
  searchControlState?: ISearchControlState;
  objectId?: string;
}

const TravelDestinationFieldWithTopDestinations = <
  TQuery extends object,
  TVariables extends { text: string }
>(
  props: ITravelDestinationFieldWithTopDestinationsProps<TQuery, TVariables>
): React.ReactElement => {
  const [travelType] = useField<TravelType | null>('travelType');
  const {
    customLabel,
    document,
    disabled,
    dataMapper,
    variablesMapper,
    searchControlState,
    objectId,
  } = props;
  const { mobile } = useDeviceType();
  const topDestinationIds = useTopDestinationAutocompleteIds(travelType);
  const [topDestinationsShown, setTopDestinationShown] = useState<
    boolean | null
  >(null);
  const hotelOnlyDestinations = useTravelDestinationAutocompleteHotelOnlyDestinations();

  const onTopDestinationsChange = (shown: boolean) => {
    setTopDestinationShown(shown);
  };

  const { values, setValues } = useFormContext<ISearchControlFormState>();

  const renderSuggestion = useCallback(
    (item: ICanonicalTravelDestination, query: string) => {
      if (
        hotelOnlyDestinations.includes(item.id) &&
        travelType !== TravelType.Hotel
      ) {
        return (
          <TravelDestinationHotelOnlySuggestion item={item} query={query} />
        );
      } else {
        return <TravelDestinationSuggestion item={item} query={query} />;
      }
    },
    [hotelOnlyDestinations, travelType]
  );

  return (
    <GenericTravelDestinationFieldWithCount<TQuery, TVariables>
      customLabel={customLabel}
      disabled={disabled}
      queryDocument={document}
      dataMapper={dataMapper}
      variablesMapper={variablesMapper}
      caption={
        <TravelDestinationFieldCaption
          searchControlState={searchControlState}
          objectId={objectId}
        />
      }
      onSelect={(item, text, popupControl) => {
        const isHotelOnlyDestination =
          !topDestinationsShown &&
          hotelOnlyDestinations.includes(item.id) &&
          values?.travelType === TravelType.Package;
        const isTopDestination =
          topDestinationsShown &&
          (!item.id || topDestinationIds.includes(item.id));

        if (isHotelOnlyDestination) {
          setValues({ ...values, travelType: TravelType.Hotel });
          trackHotelOnlyDestinationClick();
        }

        if (isTopDestination) {
          trackTopDestination(
            mapCanonicalDestinationToTrackableDestination(item),
            text
          );
        } else {
          trackSelectSuggestion(
            mapCanonicalDestinationToTrackableDestination(item),
            text
          );
        }

        if (!mobile && (isTopDestination || isHotelOnlyDestination)) {
          popupControl.close();
        }
      }}
      renderSuggestion={renderSuggestion}
    >
      <SearchHistoryTopDestinationComboSuggestions
        travelType={travelType}
        onTopDestinationsChange={onTopDestinationsChange}
      />
    </GenericTravelDestinationFieldWithCount>
  );
};

export default TravelDestinationFieldWithTopDestinations;
